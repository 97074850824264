@import "sassvariables";
@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

body {
  width: 100vw;
  margin: 0;
  background-color: $offWhite;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: #444440;
  overflow: overlay;
}

p {
  font-size: 1.3rem;
}

.component {
  // Component outer box
  position: relative;
  min-height: 5vh;
  width: 100vw;
  padding-bottom: 15vmin;
}

.componentMain {
  // Content area for components
  position: relative;
  width: 1200px;
  max-width: 80vw;
  left: 50%;
  transform: translateX(-50%);
  padding: 5rem 0 20vmin 0;
}

.componentTitle {
  // Faded section titles
  position: absolute;
  bottom: -4vmin;
  left: 0;
  margin: 0;
  color: rgba($color: $grayish, $alpha: 0.5);
  font-size: 20vmin;
  user-select: none;
}

// Scrollbar
::-webkit-scrollbar {
  width: 0.75rem;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba($color: black, $alpha: 0.6);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: black, $alpha: 0.8);
}

// Media queries
@media only screen and (max-width: 1200px) {
  //Tablets 601px -> 1200px
  html {
    font-size: 12px;
  }
  .componentContent {
    padding: 4rem 15% 4rem 15%;
  }
}
@media only screen and (max-width: 600px) {
  //Big smartphones 426px -> 600px
  html {
    font-size: 8px;
  }
  .componentContent {
    padding: 3rem 10% 3rem 10%;
  }

  .componentTitle {
    font-size: 15vmin;
  }
}
@media only screen and (max-width: 425px) {
  //Small smartphones 325px -> 425px
  html {
    font-size: 6px;
  }
  .componentContent {
    padding: 1rem 5% 1rem 5%;
  }
}
