// Color variables
$offWhite: #f1f3f4;
$lightGray: #d7d8d9;
$lightish: #9b9b9b;
$grayish: #6b6b6b;
$darkish: #333333;
$dark: #1f1f1f;
$darker: #090909;
$hexagon: polygon(50% 0, 92.5% 25%, 92.5% 75%, 50% 100%, 7.5% 75%, 7.5% 25%);

$lightBackground: #f1f3f4;
$darkBackground: #121212;

$pixagonOrange: #ffa500;
$pixagonOrangeDark: #cc7000;

// Font variables
$htmlTags: "La Belle Aurore", cursive;
$hFontSize: 15rem;

// Button variables
$buttonBg: transparent;
$buttonTextColor: $pixagonOrangeDark;
$buttonFontWeight: bold;
$buttonHover: $pixagonOrange;
$buttonBorder: 2px solid $pixagonOrangeDark;
$buttonBorderRadius: 3px;
$buttonPadding: 12px 24px;
$buttonTransition: color 0.3s ease, border 0.3s ease;
