@import "src/sassvariables.scss";

.contactComponent {
  background-color: $darkish;
  color: white;
  text-align: center;
}

.contactMain {
  padding-bottom: 10vmin;
}

// Form
.formLabel {
  position: relative;
  font-size: 1.5rem;
}

.contactForm {
  display: grid;
  grid-template-columns: 1;
  margin-top: 2rem;
  grid-auto-flow: row;
}

.contactForm label {
  height: fit-content;
  margin: 0.8rem 0 0 0.5rem;
  text-align: start;
  font-size: 1.2rem;
}

.yourName,
.companyName,
.emailAdress {
  padding: 0.5rem;
  background-color: transparent;
  color: $offWhite;
  font-size: 1.25rem;
  font-family: inherit;
  border: 1px solid $offWhite;
  border-radius: 3px;
}

.contactForm input:focus {
  outline: none;
  border: 1px solid $pixagonOrangeDark;
}

.contactForm textarea {
  min-height: 10rem;
  background-color: transparent;
  border: 1px solid $offWhite;
  border-radius: 3px;
  padding: 0.5rem;
  color: $offWhite;
  font-family: inherit;
  resize: none;
}

.contactForm textarea:focus {
  outline: none;
  border: 1px solid $pixagonOrangeDark;
}

.formSubmit {
  width: fit-content;
  background-color: $buttonBg;
  color: $offWhite;
  font-weight: $buttonFontWeight;
  margin-top: 1rem;
  padding: $buttonPadding;
  border-radius: $buttonBorderRadius;
  border: 1px solid $offWhite;
  cursor: pointer;
  transition: $buttonTransition;

  &:hover {
    border-color: $pixagonOrangeDark;
    color: $pixagonOrangeDark;
  }
}

// Contact info
.contactBox {
  margin: 4rem 5vw 0 5vw;
  display: inline-block;
}

.contactBox i {
  font-size: 1.75rem;
  color: $pixagonOrangeDark;
}

.contactBox p {
  margin-top: 0.5rem;
}

// Alertbox
.alertBox {
  position: fixed;
  height: auto;
  width: 100vw;
  top: 0;
  padding: 4vh 0 4vh 0;
  background-color: #30752b;
  visibility: hidden;
  transition: top 2s ease-in-out;
  transition-duration: 2s;
  z-index: 99;
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .contactForm {
    grid-template-columns: 50% 50%;
    grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem;
    column-gap: 1rem;
  }

  .yourName,
  .companyName,
  .emailAdress {
    border: 2px solid $offWhite;
    grid-column: 1;
  }

  .contactForm input:focus {
    border: 2px solid $pixagonOrangeDark;
  }

  .yourName {
    grid-row: 2;
  }

  .companyName {
    grid-row: 4;
  }

  .emailAdress {
    grid-row: 6;
  }

  .contactForm textarea {
    min-height: 0;
    border: 2px solid $offWhite;
    grid-column: 2;
    grid-row: 2 / 7;
    width: 92.5%;
  }

  .textAreaLabel {
    grid-row: 1;
    grid-column: 2;
  }

  .contactForm textarea:focus {
    border: 2px solid $pixagonOrangeDark;
  }

  .formSubmit {
    grid-column: 2;
    grid-row: 7;
    border: 2px solid $offWhite;
  }
}
