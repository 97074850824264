@import "src/sassvariables.scss";

.cookieBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8vmin 0 10px 0;
  background-color: transparent;
  background-image: url("../../media/headImgSmall.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  background-position: top;
  color: #ffffff;
  text-align: center;
  z-index: 1000;
  display: none;
}

.cookieBanner button {
  background-color: $buttonBg;
  color: $buttonTextColor;
  font-weight: $buttonFontWeight;
  padding: $buttonPadding;
  border-radius: $buttonBorderRadius;
  border: 1px solid $pixagonOrangeDark;
  cursor: pointer;
  transition: $buttonTransition;

  &:hover {
    border-color: $buttonHover;
    color: $buttonHover;
  }
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .cookieBanner button {
    border: $buttonBorder;
  }
}
