@import "src/sassvariables.scss";

footer {
  width: 100%;
  margin: 0;
  background-color: $darkish;
  background-image: url("../../media/headImgSmall.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  color: $offWhite;
}

footer p {
  display: inline-block;
  margin-top: 5vw;
  font-size: 1.25rem;
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  footer {
    background-position: 0 -30px;
  }
}
