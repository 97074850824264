@import "src/sassvariables.scss";

.portfolioComponent {
  background-color: $offWhite;
  color: $dark;
}

.portfolioMain {
  display: grid;
  grid-template-columns: 1fr;
}

// Project descriptions
.projectDescription {
  margin-top: 5rem;
}

.projectTitle {
  color: $pixagonOrangeDark;
  font-size: 1.5rem;
}

// Project images
.projectImages {
  position: relative;
  aspect-ratio: 16/9;
}

.projectImages a {
  text-decoration: none;
  color: inherit;
}

.projectImages img {
  height: auto;
  border: 1px solid $lightish;
  border-radius: 3px;
  transition: transform 0.2s;
  cursor: pointer;
}

.projectImages img:hover {
  transform: scale(1.1);
}

.projectImgBig {
  position: absolute;
  width: 90%;
  left: 0;
  bottom: 2%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: top;
}

.projectImgTablet {
  position: absolute;
  width: 30%;
  right: 0;
  bottom: 1%;
}

.projectImgPhone {
  position: absolute;
  width: 15%;
  right: 25%;
  bottom: 0;
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .portfolioMain {
    grid-template-columns: repeat(2, 1fr);
    gap: 10%;
    padding-bottom: 30vmin;
  }

  .projectDescription {
    margin-top: 0;
  }
}
