@import "src/sassvariables.scss";

.splashComponent {
  height: 70vh;
  max-height: 100vw;
  background-color: $offWhite;
  background-image: url("../../media/headImg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.splashMain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splashMain p {
  color: $offWhite;
  font-size: 10rem;
  font-weight: 500;
  margin: 0;
}

// SVG
.svgHex {
  vertical-align: middle;
  margin: 0 -2rem 0 -2.5rem;
}

#line1 {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: drawLine1 3s infinite alternate;
  animation-delay: 0s;
}

#line2 {
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  animation: drawLine2 3s infinite alternate;
  animation-delay: 2s;
}

#line3 {
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  animation: drawLine3 3s infinite alternate;
  animation-delay: 1s;
}

@keyframes drawLine1 {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawLine2 {
  0% {
    stroke-dashoffset: 110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawLine3 {
  0% {
    stroke-dashoffset: 110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@media only screen and (max-width: 600px) {
  .splashMain p {
    font-size: 10rem;
  }

  .svgHex {
    height: 10rem;
    width: 10rem;
    margin: 0 -1.5rem 0 -2rem;
  }
}
