@import "src/sassvariables.scss";

.serviceComponent {
  background-color: $darkish;
  color: white;
}

.serviceMain {
  display: grid;
  grid-template-columns: 1;
}

// Icon Boxes
.serviceIconLeft,
.serviceIconRight {
  position: relative;
  width: 10rem;
  margin: 2rem 0 0 50%;
  transform: translateX(-50%);
  aspect-ratio: 1/1;
  background-color: $offWhite;
  clip-path: $hexagon;
  -webkit-clip-path: $hexagon;
  text-align: center;
}

.serviceIconInner {
  position: absolute;
  width: 95%;
  top: 2.5%;
  left: 2.5%;
  aspect-ratio: 1/1;
  background-color: $darkish;
  clip-path: $hexagon;
  -webkit-clip-path: $hexagon;
}

.faIcon {
  margin-top: 50%;
  transform: translateY(-50%);
  color: $pixagonOrangeDark;
  font-size: 3rem;
}

// Text boxes
.serviceTextLeft,
.serviceTextRight {
  margin-top: 1rem;
}

.tileTitle {
  margin: 0;
  font-size: 1.7rem;
  color: $pixagonOrangeDark;
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .serviceMain {
    grid-template-columns: repeat(6, 1fr);
  }

  .serviceIconLeft {
    grid-column: 1;
  }

  .serviceIconRight {
    grid-column: 6;
  }

  .serviceIconLeft,
  .serviceIconRight {
    margin-top: 0;
  }

  .serviceTextLeft {
    text-align: end;
    margin-right: 5%;
    grid-column: span 5;
  }

  .serviceTextRight {
    text-align: start;
    margin-left: 5%;
    grid-column: span 5;
  }

  .serviceTextLeft,
  .serviceTextRight {
    margin-top: 1.5rem;
  }

  .serviceIconLeft:nth-child(1),
  .serviceTextRight:nth-child(2) {
    grid-row: 1;
  }

  .serviceIconRight:nth-child(3),
  .serviceTextLeft:nth-child(4) {
    grid-row: 2;
  }

  .serviceIconLeft:nth-child(5),
  .serviceTextRight:nth-child(6) {
    grid-row: 3;
  }

  .serviceIconRight:nth-child(7),
  .serviceTextLeft:nth-child(8) {
    grid-row: 4;
  }

  .serviceIconLeft:nth-child(9),
  .serviceTextRight:nth-child(10) {
    grid-row: 5;
  }
}
