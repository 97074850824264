@import "src/sassvariables.scss";

// Mobile Navigation
.mobileNavButton {
  position: fixed;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #ffffff90;
  color: #303030;
  font-size: 2.5rem;
  border: 2px solid #606060;
  border-radius: 5px;
  visibility: visible;
  cursor: pointer;
  z-index: 10;
}

.mobileNav {
  position: fixed;
  top: 2.5vh;
  left: 0;
  width: 0;
  height: 95vh;
  margin: 0;
  padding: 0;
  background-color: $offWhite;
  opacity: 97.5%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 1px 0 10px -5px #00000050;
  transition: width 1s ease;
  z-index: 9;
}

.mobileNavList {
  display: none;
  padding: 0;
  list-style: none;
  transition: display 0.5s;
}

.mobileNavList li {
  margin: 10px 10%;
  padding: 10px 0;
  font-size: 2rem;
  border-bottom: 1px solid black;
}

.mobileNav a:hover {
  color: #ccc;
}

// Links
.link {
  color: inherit;
  text-decoration: none;
}

// Larger sizes
.bigNavBg {
  position: absolute;
  width: 100%;
  z-index: 99;
}

.bigNav {
  position: absolute;
  display: none;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 12;
}

.bigNav li {
  display: inline-block;
  margin: 0 2rem 0 2rem;
  color: $offWhite;
  font-size: 1.2rem;
  border-bottom: 2px solid $grayish;
  cursor: pointer;
}

.bigNav li:hover {
  border-bottom: 2px solid $pixagonOrange;
}

.leftFoot,
.rightFoot {
  position: fixed;
  display: none;
  margin: 0;
  color: $darker;
  z-index: 99;
}

// Left fixed footer
.leftFoot {
  bottom: 15rem;
}

// Right fixed footer
.rightFoot {
  left: 0;
  bottom: 7.5rem;
  text-align: start;
}

.rightFoot i {
  margin: 0 1rem;
  font-size: 1.25rem;
  transition: transform 0.2s;
}

.rightFoot i:hover {
  transform: scale(1.2);
}

.socialsList {
  list-style: none;
  padding: 0;
}

.socialsList li {
  display: inline-block;
  cursor: pointer;
}

.languageList {
  display: inline;
  padding: 0;
  list-style: none;
}

.languageList li {
  display: inline-block;
  height: 1.5rem;
  margin: 0.5rem;
}

.languageIconBig {
  display: none;
}

.languageList:hover .svFlagImg,
.languageList:hover .enFlagImg {
  visibility: visible;
}

// Foot navigation
.svFlagImg,
.enFlagImg {
  width: 2rem;
  aspect-ratio: 1.5/1;
  margin-top: 0.9rem;
  visibility: visible;
  background: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  opacity: 0.5;
  cursor: pointer;
  border: none;
  text-align: start;
  transition: opacity 0.5s, transform 0.5s, visibility 1s ease-in-out;
  transition-delay: 0.25s ease-out;
  border-radius: 3px;
}

.svFlagImg {
  background-image: url("../../media/swedishIcon.webp");
}

.enFlagImg {
  background-image: url("../../media/englishIcon.webp");
}

.svFlagImg:hover,
.enFlagImg:hover {
  opacity: 1;
  transform: scale(1.1);
}

// After lines
.mailAdress::before,
.socialsList::before,
.languageContainer::before {
  display: inline-block;
  height: 2px;
  filter: none;
  mix-blend-mode: none;
  background-color: $pixagonOrangeDark;
  content: "";
  transition: width 1s ease;
}

.mailAdress::before {
  width: 5rem;
  margin: 0 1rem 0.25rem 0;
}

.socialsList::before {
  width: 3rem;
  margin: 0 1rem 0.5rem 0;
}

.languageContainer::before {
  width: 7rem;
  margin: 0 1rem 0.5rem 0;
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .mailAdress {
    transform: rotate(90deg);
  }

  .leftFoot {
    left: -2.5%;
    bottom: 5rem;
  }

  .rightFoot {
    right: 0;
    bottom: 2rem;
    text-align: end;
  }

  .leftFoot,
  .rightFoot {
    display: block;
  }

  .languageIconBig {
    display: inline-block;
  }

  .languageIconSmall {
    display: none;
  }

  .mailAdress::after,
  .socialsList::after,
  .languageContainer::after {
    display: inline-block;
    height: 2px;
    filter: none;
    mix-blend-mode: none;
    background-color: $pixagonOrangeDark;
    content: "";
  }

  .mailAdress::before,
  .socialsList::before,
  .languageContainer::before {
    display: none;
  }

  .mailAdress::after {
    width: 5rem;
    margin: 0 0 0.25rem 1rem;
  }

  .socialsList::after {
    width: 3rem;
    margin: 0 0 0.5rem 1rem;
  }

  .languageContainer::after {
    width: 5rem;
    margin: 0 0 0.5rem 1rem;
  }

  .mobileNavButton {
    display: none;
  }

  .bigNav {
    display: block;
  }

  .svFlagImg,
  .enFlagImg {
    visibility: hidden;
  }
}
