@import "src/sassvariables.scss";

.aboutMain {
  display: grid;
  grid-template-columns: 1;
}

.aboutImage {
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
}

/* ----- Large screens ----- */
@media only screen and (min-width: 1200px) {
  .aboutMain {
    grid-template-columns: 30% 70%;
  }

  .aboutImage {
    width: 75%;
  }
}
